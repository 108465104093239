import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({location, ...props}) => (
  <Layout location={location}>
    <SEO title="404: Not found" />
    <section className="uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-height-1-1" data-uk-height-viewport="offset-top: true">
        <div className="uk-container uk-container-xsmall">
            <div className="uk-padding">
                <p className="uk-h6 uk-text-muted uk-margin-small-bottom uk-text-uppercase">
                    <strong>&mdash;</strong> 404 This page does not exist
                </p>
                <h1 className="uk-margin-small-top uk-visible@m">The easiest way to solve a problem is to deny it exists.</h1>
                <h3 className="uk-margin-small-top uk-hidden@m">The easiest way to solve a problem is to deny it exists.</h3>
                <p className="uk-text-bold uk-margin-xlarge-top uk-margin-large-top@m">
                    <a className="uk-button uk-button-large uk-button-text cta-button cta-button-next" href={`/`}>See the latest</a>
                </p>
            </div>
        </div>
    </section>
  </Layout>
)

export default NotFoundPage
